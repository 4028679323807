var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { RatingIcons } from "../interfaces/SearchPage";
var Rating = /** @class */ (function (_super) {
    __extends(Rating, _super);
    function Rating() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getElements = function (number) {
            var elements = [];
            var icon, iconClass, iconEmpty;
            switch (_this.props.icon) {
                case RatingIcons.Heart:
                default:
                    icon = "#icon--heart-filled";
                    iconEmpty = "#icon--heart-empty-thin";
                    iconClass = "heart";
                    break;
                case RatingIcons.Cash:
                    icon = "#icon--pound";
                    iconEmpty = "#icon--pound-empty";
                    iconClass = "pound";
                    break;
            }
            for (var i = 0; i < number; i++) {
                elements.push((React.createElement("span", { className: "rating__item", key: "icon-".concat(i) },
                    React.createElement("svg", { className: "rating__icon ".concat(iconClass) },
                        React.createElement("use", { xlinkHref: icon })))));
            }
            if (_this.props.fillRest) {
                var otherNum = (5 - number);
                for (var j = 0; j < otherNum; j++) {
                    elements.push((React.createElement("span", { className: "rating__item", key: "icon-empty-".concat(j) },
                        React.createElement("svg", { className: "rating__icon ".concat(iconClass) },
                            React.createElement("use", { xlinkHref: iconEmpty })))));
                }
            }
            return elements;
        };
        _this.render = function () {
            if (_this.props.number === 0)
                return null;
            var elements = _this.getElements(_this.props.number);
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "rating" }, elements)));
        };
        return _this;
    }
    return Rating;
}(React.PureComponent));
export default Rating;
